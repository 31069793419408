import React from "react";
import { MenuItem } from "components/molecules/menuItem";
import { MENU_BACKGROUND, WHITE_BACKGROUND } from "constants/colors";
import FlowRow from "components/atoms/flowrow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group"
import Col from "components/atoms/col";
import { SPACE } from "constants/dimen";

const Menu = (props) => {

    const styles = {
        full: {
            zIndex: 999999,
            height: '100%',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: MENU_BACKGROUND,
            backdropFilter: 'blur(5px)'
        },
        close: {
            padding: `${SPACE} 0`,
            width: '100%',
        }
    }

    const { menuRef, isOpen, onClick, activeIndex, closeMenu, items } = props;

    return (
        <CSSTransition
            nodeRef={menuRef}
            in={isOpen}
            timeout={500}
            mountOnEnter
            unmountOnExit
            classNames="fade">

            <div ref={menuRef} style={styles.full}>

                <Col full>

                    <FontAwesomeIcon
                        style={styles.close}
                        fontSize={50}
                        width={50}
                        height={50}
                        color={WHITE_BACKGROUND}
                        icon={faClose}
                        onClick={closeMenu}
                    />

                    <FlowRow>
                        {
                            items.map
                                (
                                    (el, index) =>
                                        <MenuItem
                                            isActive={activeIndex === undefined || activeIndex === index}
                                            key={`menu: ${el.name}`}
                                            name={el.name}
                                            onClick={() => onClick(index)}
                                            icon={el.icon}
                                        />
                                )
                        }
                    </FlowRow>

                </Col>
            </div>
        </CSSTransition >
    )
}

export default Menu;