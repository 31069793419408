import React from 'react';

const Space = (props) => {
    const styles = {
        space: {
            height: props.revert ? props.width : props.height,
            width: props.revert ? props.height : props.width,
            display: props.float && 'inline-block',
        }
    }

    return <div style={styles.space}></div>;
}

export default Space;