import React from 'react';

const PrimaryText = (props) => {
    const styles = {
        text: {
            color: 'white',
            fontSize: '1.1em',
            margin: 0,
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            lineHeight: 2,
            fontWeight: props.bold && 700,
            textAlign: props.textAlign
        }
    }

    return <p style={styles.text}>{props.text}</p>;
}

export default PrimaryText;