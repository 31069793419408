import React from "react"
import { SPACE } from "constants/dimen"
import RoundedBox from "components/atoms/roundedBox"
import MediumTitle from "components/atoms/mediumTitle"
import { HEADER } from "constants/colors"

export default function Footer() {

    return (
        <RoundedBox background={HEADER} padding={SPACE}>
            <MediumTitle textAlign={"center"} text="Copyright © 2023 | Giorgio Baldassarre" />
        </RoundedBox>
    )
}