import React from "react"
import BigTitle from "components/atoms/bigTitle"
import Col from "components/atoms/col"
import Space from "components/atoms/space"
import { FREE_TIME } from "data/freetime"
import TechArray from "components/molecules/techArray"
import { SPACE } from "constants/dimen"

export default function FreeTime() {

    return (
        <Col>
            <BigTitle text="Free Time" />

            <Space height={SPACE} />

            <TechArray
                color={'white'}
                iconSize={100}
                padding={30}
                tech={FREE_TIME} />
        </Col>
    )
}