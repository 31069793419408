import { TILE_BACKGROUND } from "constants/colors";
import Col from "components/atoms/col";
import Row from "components/atoms/row";
import RoundedBox from "components/atoms/roundedBox"
import RoundedText from "components/atoms/roundedText";
import React, { Fragment } from "react";
import Space from "components/atoms/space";
import PrimaryText from "components/atoms/primaryText";
import TechArray from "components/molecules/techArray";
import RoundedButton from "components/atoms/roundedButton";
import { useMediaQuery } from 'react-responsive'
import Title from "components/atoms/title";
import { SPACE, SPACE_2X } from "constants/dimen";

const Project = (props) => {

    const styles = {
        image: {
            height: 500,
            width: 'auto',
            borderRadius: 10,
        }
    }

    const { type, name, images, description, tech, link } = props.project;
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <RoundedBox padding={SPACE} background={TILE_BACKGROUND}>
            <Col>
                <RoundedText text={type} />

                <Title text={name} />

                <Space height={SPACE} />

                <PrimaryText text={description} />

                <Space height={SPACE} />

                <Row scroll padding={SPACE} margin={`0 -${SPACE}px`}>
                    {
                        images && images.map(
                            (src, index) => <Fragment key={`Project ${name} screen: ${index}`}>
                                {index !== 0 && <Space float width={SPACE} />}
                                <img alt={`Project ${name}, screen ${index + 1}`} style={styles.image} src={src} />
                            </Fragment>
                        )
                    }
                </Row>

                <>
                    <Space height={SPACE} />

                    <Row full>
                        <TechArray tech={tech} />
                        {link && !isMobile && <RoundedButton link={link} text={"check it out"} />}
                    </Row>
                </>

                {
                    link && isMobile && <>
                        <Space height={SPACE_2X} />
                        <RoundedButton full link={link} text={"check it out"} />
                    </>
                }

            </Col>
        </RoundedBox>
    )
}

export default Project;