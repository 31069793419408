import { faAndroid, faHtml5, faJava, faPhp, faPython } from "@fortawesome/free-brands-svg-icons"
import { faBook, faCalculator, faDiagramProject, faVolleyball } from "@fortawesome/free-solid-svg-icons"

export const EDUCATION = [
    {
        title: 'Master Degree: Computer Science Engineering',
        text: 'Thesis about Social Network Analysis and Internet of Things',
        score: '110 cum laude / 110',
        logo: '/career/unirc.png',
        location: 'Università mediterranea',
        website: 'https://www.unirc.it/',
        tech: [faPython, faAndroid, faDiagramProject]
    },
    {
        title: "Bachelor's Degree: ICT Engineering",
        text: 'Thesis about UXD applied to University web portal',
        score: '110 cum laude / 110',
        logo: '/career/unirc.png',
        location: 'Università mediterranea',
        website: 'https://www.unirc.it/',
        tech: [faJava, faPhp, faHtml5]
    },
    {
        title: "Scientific Graduation",
        text: 'Project about dynamism of life',
        score: '87 / 100',
        logo: '/career/vinci.png',
        location: 'Liceo Scientifico L. Da vinci',
        website: 'https://www.liceovinci.eu/',
        tech: [faCalculator, faBook, faVolleyball]
    },
]