//not used - in case of fast switch
export const GREEN_ACCENT = '#27C499';
export const ACCENT = '#2196F3';

export const TILE_BACKGROUND = '#222423';
export const MAIN_BACKGROUND = '#090909';
export const INNER_BACKGROUND = '#0F1311';
export const WHITE_BACKGROUND = '#FFFFFF';

export const SEPARATOR = '#FFFFFF11';
export const TIMELINE_COLOR = '#FFFFFF55';

export const HEADER = '#00000055';
export const MENU_BACKGROUND = '#090909bb';