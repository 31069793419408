import React from 'react';

const MediumTitle = (props) => {
    const styles = {
        text: {
            color: 'white',
            marginTop: 10,
            marginBottom: 0,
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            textAlign: props.textAlign
        }
    }

    return <h3 style={styles.text}>{props.text}</h3>;
}

export default MediumTitle;