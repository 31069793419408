import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ACCENT, INNER_BACKGROUND } from "constants/colors"
import React from 'react';

const RoundedTech = (props) => {
    const { index, icon } = props;
    const iconSize = props.iconSize || 25;
    const padding = props.padding || 15;

    //if is passed -> set || color the first with ACCENT
    const background = props.background || (index === 0 ? ACCENT : INNER_BACKGROUND);
    
    const styles = {
        text: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.5em',
            margin: 0,
        },
        techIcon: (index) => ({
            borderRadius: '100%',
            width: iconSize,
            height: iconSize,
            padding: padding || 15,
            background: background,
            translate: `-${index * 10}px`,
            border: '1px solid #ffffff33'
        })
    }


    return <FontAwesomeIcon
        fontSize={iconSize}
        width={iconSize}
        icon={icon}
        color={props.color}
        style={styles.techIcon(index)}
    />;
}

export default RoundedTech;