import BigTitle from "components/atoms/bigTitle"
import Col from "components/atoms/col"
import Space from "components/atoms/space"
import Project from "components/organisms/project"
import React, { Fragment } from "react"
import { PROJECTS } from "data/projects"
import { SPACE, SPACE_2X } from "constants/dimen"

export default function Projects() {

    return (
        <Col>
            <BigTitle text="Projects" />

            <Space height={SPACE} />

            {
                PROJECTS.map((project, index) =>
                    <Fragment key={`Project ${project.name}`}>
                        {index > 0 && <Space height={SPACE_2X} />}
                        <Project project={project} />
                    </Fragment>
                )
            }

        </Col>
    )
}