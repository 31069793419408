import React from 'react';

const SquaredBox = (props) => {

    const size = 80;

    const styles = {
        image: {
            padding: props.padding,
            borderRadius: 10,
            height: size,
            width: size,
        },
    }

    return (
        <img
            alt={props.name}
            height={size}
            width={size}
            src={props.src}
            style={styles.image}
        />
    )

}

export default SquaredBox;