import BigTitle from "components/atoms/bigTitle";
import Col from "components/atoms/col";
import React, { Fragment } from 'react';
import Space from "components/atoms/space";
import Company from "components/organisms/company";
import { CAREER } from "data/career";
import { SPACE, SPACE_2X } from "constants/dimen";

export default function Career() {

    return (
        <Col>

            <BigTitle text="Career" />

            <Space height={SPACE} />

            {
                CAREER.map((el, index) =>
                    <Fragment key={el.name}>
                        {index > 0 && <Space height={SPACE_2X} />}
                        <Company name={el.name} roles={el.roles} />
                    </Fragment>
                )
            }

        </Col>
    )
}
