import { faAndroid, faHtml5 } from "@fortawesome/free-brands-svg-icons"
import { faBriefcase, faBuilding, faCode, faFaceSmileBeam, faScissors, faShop, faVolleyball } from "@fortawesome/free-solid-svg-icons"

export const PROJECTS = [
    {
        type: "App",
        name: "Volley Match Day",
        description: "Team balancer for creating balanced volleyball matches. User can create players, moving them in the court by drag n drop, balancing them and save the match.",
        images: ["projects/matchDay/0.webp", "projects/matchDay/1.webp", "projects/matchDay/2.webp", "projects/matchDay/3.webp"],
        link: "https://play.google.com/store/apps/details?id=it.giobalda.matchday",
        tech: [faAndroid, faCode, faVolleyball],
    },
    {
        type: "App",
        name: "Android Pokedex",
        description: "App created for fun, putting Android advanced course in practice: clean architecture, Retrofit, coroutines, notifications",
        images: ["projects/pokedex/0.png", "projects/pokedex/1.png", "projects/pokedex/2.png", "projects/pokedex/3.png"],
        tech: [faAndroid, faCode, faFaceSmileBeam],
    },
    {
        type: "Web App",
        name: "Project & Management Website",
        description: "Website for a project management company based in Calabria.",
        images: ["projects/pm/0.webp", "projects/pm/1.webp", "projects/pm/2.webp", "projects/pm/3.webp"],
        link: "https://www.projectandmanagement.it/",
        tech: [faBuilding, faBriefcase, faHtml5],
    },
    {
        type: "Web App",
        name: "KARM Website",
        description: "Ecommerce website for a freelance hairstylist.",
        images: ["projects/karm/0.webp", "projects/karm/1.webp", "projects/karm/2.webp", "projects/karm/3.webp"],
        link: "https://karmhairstylist.com/",
        tech: [faShop, faScissors, faHtml5],
    }
]