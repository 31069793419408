import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryText from "components/atoms/primaryText";
import Col from "components/atoms/col";
import { ACCENT } from "constants/colors";
import Space from "components/atoms/space";
import { SPACE } from "constants/dimen";

export const MenuItem = (props) => {

    const iconSize = props.isActive ? 80 : 50;

    return (
        <Col
            full
            onClick={props.onClick}
            alignItems={'center'}
            justifyContent={'center'}
            flexBasis={'50%'}
        >
            <PrimaryText bold={props.isActive} text={props.name} />

            <Space height={SPACE} />

            <FontAwesomeIcon
                fontSize={iconSize}
                width={iconSize}
                height={iconSize}
                color={ACCENT}
                icon={props.icon}
            />
        </Col>)
}
