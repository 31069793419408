import { faCode, faGraduationCap, faHome, faSuitcase } from "@fortawesome/free-solid-svg-icons";

export const MENU = [
    {
        name: 'Intro', icon: faHome
    },
    {
        name: 'Career', icon: faSuitcase
    },
    {
        name: 'Education', icon: faGraduationCap
    },
    {
        name: 'Projects', icon: faCode
    },
]
