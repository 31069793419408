import React, { Fragment } from "react"
import BigTitle from "components/atoms/bigTitle"
import Col from "components/atoms/col"
import Row from "components/atoms/row"
import Space from "components/atoms/space"
import EducationStep from "components/organisms/educationStep"

import { EDUCATION } from "data/education"
import { SPACE } from "constants/dimen"
import { useMediaQuery } from "react-responsive"

export default function Education() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <Col>
            <BigTitle text="Education" />

            <Space height={SPACE} />

            <Row revert={isMobile} scroll padding={`0 0 ${SPACE}px 0`}>

                {
                    EDUCATION.map((el, index) =>
                        <Fragment key={`Education step: ${index}`}>
                            {index !== 0 && <Space float revert={isMobile} width={SPACE} height={1} />}

                            <EducationStep 
                                width={isMobile ? 'calc(100vw - 80px)' : 500} 
                                display={isMobile ? 'block' : 'inline-block'} 
                                step={el} />
                        </Fragment>
                    )
                }
            </Row>
        </Col>
    )
}