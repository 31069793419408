import Row from 'components/atoms/row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import { HEADER } from 'constants/colors';
import { SPACE } from 'constants/dimen';
import { faShare } from '@fortawesome/free-solid-svg-icons';

export default function Header(props) {

    const iconSize = 40;

    const styles = {
        text: {
            color: 'white',
            fontSize: '2.5em',
            fontFamily: 'dimitri'
        },
        logo: {
            color: 'white',
            background: 'white'
        },
        header: {
            position: 'fixed',
            background: HEADER,
            zIndex: 999,
            padding: 10,
            width: `calc(100% - ${SPACE}px)`,
            backdropFilter: 'blur(5px)'
        }
    }

    return (
        <div style={styles.header}>
            <Row full>
                <FontAwesomeIcon
                    onClick={props.onClick}
                    fontSize={iconSize}
                    height={iconSize}
                    width={iconSize}
                    color='white'
                    icon={props.icon} />

                <code style={styles.text} onClick={props.onLogoClick}>GB</code>

                <FontAwesomeIcon
                    onClick={props.onSocial}
                    fontSize={iconSize}
                    height={iconSize}
                    width={iconSize}
                    color='white'
                    icon={faShare} />

            </Row>
        </div>
    )
}