import React, { Fragment } from "react";

import Row from "components/atoms/row"
import Space from "components/atoms/space";
import SquaredBox from "components/atoms/squaredbox";
import { SPACE } from "constants/dimen";

const AppsArray = (props) => {

    return (
        props.apps && <Row scroll padding={SPACE} margin={`0 -${SPACE}px`}>
            {
                props.apps.map(
                    (el, index) =>
                        <Fragment key={`Company app: ${el.name}`}>
                            {index !== 0 && <Space float width={SPACE} />}
                            <a target="_blank" rel="noopener noreferrer" href={el.link}>
                                <SquaredBox key={`app icon ${el.name}`} name={el.name} src={el.icon} />
                            </a>
                        </Fragment>
                )
            }
        </Row>
    )
}



export default AppsArray;
