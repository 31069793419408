import React from 'react';

const Col = (props) => {
    const styles = {
        row: {
            flexDirection: props.revert ? 'row' : 'column',
            justifyContent: props.justifyContent || 'space-between',
            alignItems: props.alignItems,
            display: 'flex',
            padding: props.padding,
            flex: props.full ? 1 : 0,
            borderLeft: props.border && '0.5px solid white',
            margin: props.margin,
            flexBasis: props.flexBasis
        },
    }

    return (
        <div onClick={props.onClick} style={styles.row}>{props.children}</div>
    )

}

export default Col;