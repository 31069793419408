import React from 'react';

const RoundedBox = (props) => {
    const styles = {
        box: {
            borderRadius: 10,
            background: props.background,
            color: 'white',
            flex: props.width ? 'unset' : 1,
            width: props.width,
            padding: props.padding,
            display: props.display
        },
    }

    return (
        <div style={styles.box}>{props.children}</div>
    )

}

export default RoundedBox;