import React from 'react';

const Row = (props) => {
    const styles = {
        row: {
            flexDirection: props.flexDirection || props.revert ? 'column' : 'row',
            justifyContent: props.full ? 'space-between' : '',
            alignItems: props.alignItems || 'center',
            display: props.scroll ? '' : 'flex',
            whiteSpace: props.scroll && 'nowrap',
            overflow: props.scroll && 'scroll',
            padding: props.padding,
            maxWidth: '100%',
            margin: props.margin
        },
    }

    return (
        <div style={styles.row}>{props.children}</div>
    )

}

export default Row;