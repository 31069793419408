import './App.css';

import React, { useRef, useState } from 'react';
import Header from 'components/organisms/header'
import Intro from 'components/pages/intro'
import Career from 'components/pages/career'
import Space from 'components/atoms/space'
import Projects from 'components/pages/projects'
import Education from 'components/pages/education'
import { MAIN_BACKGROUND } from 'constants/colors';
import Menu from 'components/pages/menu';
import { lockScroll, unlockScroll } from 'utils/utils';
import { InView } from 'react-intersection-observer';
import { MENU } from 'data/menu';
import FreeTime from 'components/pages/freetime';
import { SPACE, SPACE_2X, SPACE_4X } from 'constants/dimen';
import Footer from 'components/pages/footer';
import { SOCIAL_MENU } from 'data/socialMenu';

function App() {
  const styles = {
    home: {
      backgroundColor: MAIN_BACKGROUND,
      padding: SPACE,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: 1,
      maxWidth: 1200,
      margin: '0 auto'
    },
    anchor: {
      position: 'absolute',
    },
  }

  const refs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  const menuRef = useRef(null);
  const socialMenuRef = useRef(null);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSocialMenuOpen, setSocialMenu] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [sectionsData, setSectionData] = useState([
    {},
    {},
    {},
    {}
  ])

  //MAIN menu
  const onMenuClick = (index) => {
    refs[index].current?.scrollIntoView({ behavior: 'smooth' });

    closeMenu();
  }

  const openMenu = () => {
    if (isSocialMenuOpen) return;

    lockScroll()
    setMenuOpen(true)
  }

  const closeMenu = () => {
    unlockScroll()
    setMenuOpen(false)
  }

  //Social menu
  const onSocialMenuClick = (index) => {
    const link = SOCIAL_MENU[index].link;

    if (link) {
      window.open(link);
    }

    closeSocialMenu();
  }


  const openSocialMenu = () => {
    if (isMenuOpen) return;

    lockScroll()
    setSocialMenu(true)
  }

  const closeSocialMenu = () => {
    unlockScroll()
    setSocialMenu(false)
  }

  //sections
  const sections = [
    Intro,
    Career,
    Education,
    Projects,
    FreeTime
  ]


  const updateActiveSection = (index, inView, entry) => {
    sectionsData[index] = { inView, isIntersecting: entry.isIntersecting }

    setSectionData(sectionsData)

    const nextIndex = sectionsData.findIndex(el => el['inView'] === true)

    setActiveIndex(nextIndex === -1 ? 0 : nextIndex)
  }

  return (
    <>
      <Header
        icon={MENU[activeIndex].icon}
        onClick={openMenu}
        onLogoClick={() => onMenuClick(0)}
        onSocial={openSocialMenu} />

      <div style={styles.home}>

        {
          sections.map((Section, index) => <div style={{ position: 'relative' }} key={`Section ${index}`} >
            <InView
              threshold={0}
              rootMargin={`-${SPACE_2X}px`}
              onChange={(inView, entry) => updateActiveSection(index, inView, entry)}
            >
              <div style={styles.anchor} ref={refs[index]} />
              {index > 0 && <Space height={80} />}
              <Section />
            </InView>

          </div>)
        }

        <Space height={SPACE_4X} />

        <Menu
          menuRef={menuRef}
          isOpen={isMenuOpen}
          items={MENU}
          activeIndex={activeIndex}
          onClick={onMenuClick}
          closeMenu={closeMenu}
        />

        <Menu
          menuRef={socialMenuRef}
          isOpen={isSocialMenuOpen}
          items={SOCIAL_MENU}
          onClick={onSocialMenuClick}
          closeMenu={closeSocialMenu}
        />

      </div>

      <Footer />
    </>
  )
}

export default App;
