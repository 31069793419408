import React, { Fragment } from "react"
import Row from "components/atoms/row"
import Space from "components/atoms/space"
import { useMediaQuery } from 'react-responsive'

import RoundedText from "components/atoms/roundedText"
import SecondaryText from "components/atoms/secondaryText"
import TechArray from "components/molecules/techArray";
import { TILE_BACKGROUND } from "constants/colors"
import AppsArray from "components/molecules/appsArray"
import PrimaryText from "components/atoms/primaryText";
import Title from "components/atoms/title"
import { SPACE, SPACE_2X } from "constants/dimen"
import Col from "components/atoms/col"
import RoundedBox from "components/atoms/roundedBox"


const Company = (props) => {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { name, roles } = props;

    return (
        <RoundedBox padding={SPACE} background={TILE_BACKGROUND}>
            <Col>
                <RoundedText text={name} />

                <Space height={10} />

                {
                    roles && roles.map((role, index) =>
                        <Fragment key={role.name}>
                            {index > 0 && <Space height={SPACE_2X}/>}

                                <Col full key={`Company ${name} | Role ${role.name}`}>
                                    <Title text={role.name} />
                                    <SecondaryText text={role.period} />

                                    <PrimaryText text={role.description} />

                                    <Space height={SPACE} />

                                    <Row full alignItems={isMobile ? 'start' : 'center'} revert={isMobile}>
                                        <TechArray tech={role.tech} />


                                        {isMobile &&  <Space height={SPACE_2X} />}

                                        <AppsArray apps={role.apps} />
                                    </Row>
                                </Col>
                        </ Fragment>
                    )
                }
            </Col>
        </RoundedBox>
    )
}

export default Company;
