import React from 'react';

const FlowRow = (props) => {
    const styles = {
        flowRow: {
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
    }

    return (
        <div style={styles.flowRow}>{props.children}</div>
    )

}

export default FlowRow;