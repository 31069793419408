import { SPACE } from "constants/dimen";
import RoundedTech from "components/atoms/roundedTech";
import Row from "components/atoms/row"
import React from "react";

const TechArray = (props) => {

    return (
        <Row scroll margin={`0 -${SPACE}px`} padding={`0px ${SPACE}px`}>
            {
                props.tech && props.tech.map((icon, index) =>
                    <RoundedTech
                        key={`skill ${index}`}
                        icon={icon}
                        index={index}
                        color={props.color}
                        background={props.background}
                        padding={props.padding}
                        iconSize={props.iconSize}
                    />
                )
            }
        </Row>
    )
}

export default TechArray;
