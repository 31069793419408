import { ACCENT } from "constants/colors";
import React from 'react';
import { SPACE } from "constants/dimen";

const RoundedButton = (props) => {
    const styles = {
        text: {
            color: 'white',
            background: ACCENT,
            padding: props.full ? '10px 0' : `10px ${SPACE}px`,
            borderRadius: 10,
            textTransform: 'uppercase',
            width: props.full ? '100%' : 'fit-content',
            justifyContent: 'center',
            fontSize: '1.1em',
            display: 'flex'
        },
        link: {
            textDecoration: 'none'
        }
    }

    return <a style={styles.link} href={props.link} target="_blank" rel="noopener noreferrer" >
        <div style={styles.text}>{props.text}</div>
    </a>;
}

export default RoundedButton;
