import { INNER_BACKGROUND } from "constants/colors";
import React from 'react';
import { SPACE } from "constants/dimen";

const RoundedText = (props) => {
    const styles = {
        text: {
            color: 'white',
            background: INNER_BACKGROUND,
            padding: `10px ${SPACE}px`,
            borderRadius: 10,
            textTransform: 'uppercase',
            width: 'fit-content',
        }
    }

    return <div style={styles.text}>{props.text}</div>;
}

export default RoundedText;