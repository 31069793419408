import { faApple, faAndroid, faJira, faJs, faReact, faGit } from '@fortawesome/free-brands-svg-icons'
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

export const CAREER = [
    {
        name: "Nexi Payments",
        roles: [
            {
                name: "Mobile Lead Engineer",
                period: "2022 - now",
                description: "Currently leading a group of mobile engineers to develop and distribute payments apps, strictly collaborating with UX and Product Owner teams. I also cooperate with Nexi DevOps team to optimise mobile builds and apps distribution.",
                apps: [
                    {
                        icon: '/apps/nexiPos.webp',
                        name: 'nexi pos',
                        link: 'https://play.google.com/store/apps/details?id=it.nexi.mpos'
                    }
                ],
                tech: [faPeopleGroup, faAndroid, faApple, faJira],
            },
            {
                name: "LAKA Mobile Leader",
                period: "2021 - 2022",
                description: "Managing a group of mobile engineers to create and maintain payments SDKs and apps for our partners. We also provided them with tech brainstorming and support as to improve products quality.",
                apps: [
                    {
                        icon: '/apps/agos.webp',
                        name: 'AGOS',
                        link: 'https://www.agos.it/'
                    },
                    {
                        icon: '/apps/cofidis.png',
                        name: 'COFIDIS',
                        link: 'https://www.cofidis.it/'
                    },
                    {
                        icon: '/apps/esselunga.png',
                        name: 'Esselunga',
                        link: 'https://www.esselunga.it/'
                    },
                    {
                        icon: '/apps/rh.png',
                        name: 'Roadhouse',
                        link: 'https://www.roadhouse.it/'
                    },
                    {
                        icon: '/apps/evoca.png',
                        name: 'Evoca Group',
                        link: 'https://www.evocagroup.com/'
                    },
                ],
                tech: [faPeopleGroup, faAndroid, faApple, faJira],
            }
        ]
    },
    {
        name: "Sisal Entertainment",
        roles: [
            {
                name: "Mobile Leader",
                period: "2020 - 2021",
                description: "As a mobile leader, my main task consisted in managing an international team of engineers for the Turkish lottery app",
                tech: [faPeopleGroup, faAndroid, faApple, faJs],
                apps: [
                    {
                        icon: '/apps/milli.png',
                        name: 'Millipiyango',
                        link: 'https://www.millipiyangoonline.com/'
                    },
                ],
            },
            {
                name: "Mobile Architect",
                period: "2019 - 2020",
                description: "Here I started to observe the bigger picture and work with apps architecture, mobile devOps and strategic decisions. I've been the architect reponsible for Sisal Casinò",
                apps: [
                    {
                        icon: '/apps/sisalSlot.png',
                        name: 'sisal slot',
                        link: 'https://www.sisal.it/app-mobile/casino-slot-e-blackjack'
                    },
                ],
                tech: [faAndroid, faJs, faReact, faGit],
            },
            {
                name: "Mobile Developer",
                period: "2017 - 2019",
                description: "As a dev, I had the pleasure to learn ReactJS, git basics and work in challenging projects like MatchPoint, SuperEnalotto e VinciCasa",
                apps: [
                    {
                        icon: '/apps/matchpoint.png',
                        name: 'sisal matchpoint',
                        link: 'https://www.sisal.it/scommesse-matchpoint'
                    },
                    {
                        icon: '/apps/superenalotto.webp',
                        name: 'superenalotto',
                        link: 'https://www.superenalotto.it/'
                    },
                ],
                tech: [faJs, faReact, faGit]
            }
        ]
    }
]