import React from "react";

import { TILE_BACKGROUND } from "constants/colors";
import RoundedText from "components/atoms/roundedText";
import RoundedBox from "components/atoms/roundedBox";
import Row from "components/atoms/row";
import TechArray from "components/molecules/techArray";
import PrimaryText from "components/atoms/primaryText";
import MediumTitle from "components/atoms/mediumTitle";
import Space from "components/atoms/space";
import { SPACE } from "constants/dimen";

const EducationStep = (props) => {


    const {width, display} = props;
    const { location, title, text, score, logo, website, tech } = props.step;

    const imageSize = 50;

    const styles = {
        image: {
            objectFit: "contain",
        },
        circle: {
            background: 'white',
            height: imageSize,
            width: imageSize,
            borderRadius: '100%',
        }
    }


    return (
        <RoundedBox
            padding={SPACE}
            background={TILE_BACKGROUND}
            display={display}
            width={width}>
            <RoundedText text={location} />
            <Row full>
                <MediumTitle text={title} />

                <div style={styles.circle}>
                    <a href={website} target="_blank" rel="noopener noreferrer" >
                        <img
                            src={logo}
                            style={styles.image}
                            alt={title}
                            width={imageSize}
                            height={imageSize}
                        />
                    </a>
                </div>
            </Row>

            <Space height={10} />

            <PrimaryText text={text} />

            <h4>{score}</h4>

            <TechArray tech={tech} />
        </RoundedBox>
    )
}

export default EducationStep;