import React from 'react';

const SecondaryText = (props) => {
    const styles = {
        text: {
            color: '#A9AAA9',
            fontSize: '1em',
            marginTop: 10,
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        }
    }

    return <p style={styles.text}>{props.text}</p>;
}

export default SecondaryText;