import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export const SOCIAL_MENU = [
    {
        name: 'Linkedin', icon: faLinkedin, link: 'https://www.linkedin.com/in/giorgio-baldassarre/'
    },
    {
        name: 'Instagram', icon: faInstagram, link: 'https://www.instagram.com/giorgiobaldassarre'
    },
    {
        name: 'Website', icon: faGlobe, link: null,
    },
]
