import React from 'react';

const BigTitle = (props) => {
    const styles = {
        text: {
            color: 'white',
            marginTop: 10,
            marginBottom: 0
        }
    }

    return <h1 style={styles.text}>{props.text}</h1>;
}

export default BigTitle;